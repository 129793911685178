import React from 'react';
import './Skill.css';


const Skills = () => {
  const mySkill = [
    {
      name: 'HTML',
      skillIcon: '../../images/html.png'
    },
    {
      name: 'CSS',
      skillIcon: '../../images/css.png'
    },
    {
      name: 'Javascript',
      skillIcon: '../../images/javascript.png'
    },
    {
      name: 'React.js',
      skillIcon: '../../images/reactjs.png'
    },
    {
      name: 'Bootstrap',
      skillIcon: '../../images/bootstrap.png'
    },
    {
      name: 'Tailwind CSS',
      skillIcon: '../../images/tailwindcss.png'
    },
    {
      name: 'Firebase',
      skillIcon: '../../images/firebase.png'
    },
    {
      name: 'Node.js',
      skillIcon: '../../images/nodejs.png'
    },
    {
      name: 'MongoDB',
      skillIcon: '../../images/mongodb.png'
    },
    {
      name: 'Git',
      skillIcon: '../../images/git.png'
    },
    {
      name: 'Github',
      skillIcon: '../../images/github.png'
    },
    {
      name: 'WordPress',
      skillIcon: '../../images/wordpress.png'
    },
    {
      name: 'WooCommerce',
      skillIcon: '../../images/woocommerce.png'
    },
    {
      name: 'Elementor',
      skillIcon: '../../images/elementor.png'
    },
    {
      name: 'Figma',
      skillIcon: '../../images/figma.png'
    },
    {
      name: 'cPanel',
      skillIcon: '../../images/cpanel.png'
    },
    {
      name: 'CyberPanel',
      skillIcon: '../../images/cyberpanel.png'
    },
    {
      name: 'Blogging',
      skillIcon: '../../images/blogging.png'
    },
  ];
  return (
    <section id='my-skill' className='my-skills py'>
      <h2 className='h-txt'>My <span className='text-primary'>Skills</span></h2>
      <div className="container">
        <div className="skills-container">
          {
            mySkill.map((skill, index) => <div key={index} className="skill">
              <img src={skill.skillIcon} alt={skill.name} />
              <p>{skill.name}</p>
            </div>)
          }
        </div>
      </div>
    </section>
  );
};

export default Skills;