import React from 'react';
import './About.css';
const About = () => {
  return (
    <section id="about" className='about-me py'>
      <h2 className='h-txt'>About <span className='text-primary'>Me</span></h2>
      <div className="container">
        <div className="row">
          <div className='about-image'>
            <img src="https://i.ibb.co/kDHR3qz/About-Image.png" alt="biplop" />
          </div>
          <div className='about-content'>
            <p className='lg-text'>Hi, I'm MD <span className="text-primary">Biplop</span> Hossain</p>
            <p>
              I'm a Junior Front-end Web Developer from Bogura, Bangladesh. I completed Diploma In Engineering in Computer Technology from Rajshahi Polytechnic Institute. An enthusiast of technology always enjoys working with programming & web technologies.
            </p>
            <p>Currently, I have a good understanding of Front-end Web Development. Also, I am focusing on learning MERN Stack web development & try to implement it in building projects. I have working experience with the popular Content Management System WordPress.
            </p>
            <p>I love to share my knowledge what I know & what I am learning on my blog website in my free time. Because I believe that sharing knowledge makes me a better & good learner.</p>
            <h3>Connect With <span className="text-primary">Me</span></h3>
            <div className="social-media">
              <a href="https://www.linkedin.com/in/biplophossain/" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-linkedin"></i>
              </a>
              <a href="https://github.com/biplopx" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-github"></i>
              </a>
              <a href="https://facebook.com/biplopx.dev" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-facebook-f"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;