import React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  return (
    <header>
      <div className="container">
        <h1 className='logo'>MBH</h1>
        <nav onClick={() => setMenuOpen(false)} className={isMenuOpen ? "navbar menu-open" : "navbar"}>
          <ul>
            <li><NavLink to="/">Home</NavLink></li>
            <li><a href="#about">About</a></li>
            <li><a href="#my-skill">Skills</a></li>
            <li><a href="#my-projects">Projects</a></li>
            <li><a href="https://biplophossain.me/" target="_blank" rel="noreferrer">Blog</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
        <div className='menu-btn' onClick={() => { setMenuOpen(!isMenuOpen) }}>
          <i className={isMenuOpen ? "fa-solid fa-xmark" : "fa-solid fa-bars"}></i>
        </div>
      </div>
    </header>
  );
};

export default Header;