import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_xfgwz7a', 'template_dgh1u1m', form.current, 'ho0FE5bf01SFRR-TW')
      .then((result) => {
        toast.success('Email send successful');
      }, (error) => {
        toast.error(error);
      });
  };
  return (
    <section id='contact' className='py'>
      <h2 className='h-txt'>Contact <span className='text-primary'>Me</span></h2>
      <div className='container'>
        <div className='contact-container'>
          <div className='my-address'>
            <h3>Get In Touch</h3>
            <ul>
              <li><i className="fa-solid fa-envelope"></i> contact@biplophossain.me</li>
              <li><i className="fa-solid fa-location-dot"></i> Bogura,Bangladesh</li>
              <li><i className="fa-solid fa-phone"></i> +88 018529370XX</li>
            </ul>
            <div className="social-media">
              <a href="https://www.linkedin.com/in/biplophossain/" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-linkedin"></i>
              </a>
              <a href="https://github.com/biplopx" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-github"></i>
              </a>
              <a href="https://facebook.com/biplopx.dev" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-facebook-f"></i>
              </a>
            </div>
          </div>
          <div className='email-me'>
            <h3>Email Me</h3>
            <form ref={form} onSubmit={sendEmail}>
              <input type="text" name="user_name" placeholder='Enter your full name' />
              <input type="email" name="user_email" placeholder='Enter your email address' />
              <textarea name="message" placeholder='Message'>
              </textarea>
              <button type="submit" className='btn-primary'>Submit</button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Contact;