import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <p>&copy; Copyright 2022 - All rights reserved By <a href='https://facebook.com/biplopx.dev'>MD Biplop Hossain</a></p>
    </footer>
  );
};

export default Footer;