import React from 'react';
import About from '../About/About';
import Contact from '../Contact/Contact';
import HeroBanner from '../HeroBanner/HeroBanner';
import Projects from '../Projects/Projects';
import Skills from '../Skills/Skills';

const Home = () => {

  return (
    <>
      <HeroBanner />
      <About />
      <Skills />
      <Projects />
      <Contact />
    </>
  );
};

export default Home;