import React from 'react';
import './HeroBanner.css';
import { Typewriter } from 'react-simple-typewriter';

const HeroBanner = () => {
  return (
    <section className='hero-banner py'>
      <div className="container">
        <div className="row">
          <div className="hero-content">
            <p className='lg-text'>Hello I'm</p>
            <h2 className='text-primary'>MD Biplop Hossain</h2>
            <p className='lg-text'> I am <span className='text-primary'>
              <Typewriter
                words={['Frontend Web Developer', 'Blogger', 'Learner']}
                loop={0}
                cursor
                cursorStyle='_'
                typeSpeed={90}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>

            </p>
            <p className='hero-p'>A tech-enthusiastic person & web desinger/developer who loves to design & develop websites. I always love to learn something new & try to share the knowledge that I know.</p>
            <a className='btn-primary' href='https://drive.google.com/uc?export=download&id=1UXJkQzdoRu0i91VNGmw5E33Z3x8a058T' target="_blank" rel="noopener noreferrer">Download Resume</a>
          </div>
          <div className="hero-image">
            <img src='../../images/hero-image.png' alt="MD Biplop Hossain" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;