import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion"
import './Projects.css';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [projectCategories, setProjectCategories] = useState(['All']);
  const [filterValue, setFilterValue] = useState('All');

  const fetchProjects = (init) => {
    fetch('projects.json')
      .then(res => res.json())
      .then(data => {
        if (init) {
          setProjectCategories(['All', ...new Set(data.map((value) => {
            return value.category
          }))])
        }
        setProjects(data)
      })
  }

  useEffect(() => {
    fetchProjects(true)
  }, []);

  return (
    <section id='my-projects' className='my-projects py'>
      <h2 className='h-txt'>My <span className='text-primary'>Projects</span></h2>
      <div className='container'>
        <ul className='projects-filter'>
          {
            projectCategories.map(category => <li key={category} onClick={() => setFilterValue(category)} >{category}</li>)
          }
        </ul>
        <motion.div
          layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className='projects-container'>
          {
            projects
              .filter((value) => filterValue === 'All' ? true : value.category === filterValue)
              .map(project =>
                <motion.div
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  translate="no"
                  key={project.id} className='project'>
                  <div className='project-img'>
                    <img src={project.projectImg} alt='gigitechbd' />
                    <div className='project-content'>
                      <h3>{project.title}</h3>
                      <p>{project.description}</p>
                    </div>
                  </div>
                  <div className='btn-group'>
                    <a className='project-btn' href={project.liveLink}>Live Preview</a>
                    <a className='project-btn' href={project.sourceCode}>Source Code</a>
                  </div>
                </motion.div>
              )
          }
        </motion.div>
      </div>
    </section>
  );
};

export default Projects;